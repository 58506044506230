import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransformerTabConfig } from 'src/app/operating-point/models/transformer-tab-config';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root',
})
export class FingerprintService {
  private readonly TAB_CONFIGS_TYPE_1400: TransformerTabConfig[] = [
    {
      // MV
      tabId: 'mv',
      tabTitle: 'view.ehouse.mv',
      variables: [
        {
          title: 'view.ehouse.chart_1',
          variableId: 'AMB.TRCTL1N.TDt',
          chartId: 'ehouse-chart-no-1',
        },
        {
          title: 'view.ehouse.chart_2',
          variableId: 'AMB.UTCTL01.TDt',
          chartId: 'ehouse-chart-no-2',
        },
        {
          title: 'view.ehouse.chart_3',
          variableId: 'AMB.UTCTL02.TDt',
          chartId: 'ehouse-chart-no-3',
        },
      ],
    },
    {
      // CONTROL ROOM
      tabId: 'cr',
      tabTitle: 'view.ehouse.controlRoom',
      variables: [
        {
          title: 'view.ehouse.chart_1',
          variableId: 'AMB.TRCON1N.TDt',
          chartId: 'ehouse-chart-no-1',
        },
        {
          title: 'view.ehouse.chart_2',
          variableId: 'AMB.UTCON01.TDt',
          chartId: 'ehouse-chart-no-2',
        },
        {
          title: 'view.ehouse.chart_3',
          variableId: 'AMB.UTCON02.TDt',
          chartId: 'ehouse-chart-no-3',
        },
      ],
    },
  ];
  private readonly TAB_CONFIGS_TYPE_1500: TransformerTabConfig[] = [
    {
      // MV
      tabId: 'type1500Fingerprint',
      tabTitle: '',
      variables: [
        {
          title: 'view.ext_cooling.chart_1',
          variableId: 'PRC.OHPMP1.TDt',
          thresholdId: 'PRC.OHPMP1.TDt_BSF',
          chartId: 'type-1500-fingerprint-1',
        },
        {
          title: 'view.ext_cooling.chart_2',
          variableId: 'PRC.OHPMP2.TDt',
          thresholdId: 'PRC.OHPMP2.TDt_BSF',
          chartId: 'type-1500-fingerprint-2',
        },
        {
          title: 'view.ext_cooling.chart_3',
          variableId: 'PRC.OHCOUNIT1.TDt',
          thresholdId: 'PRC.OHCOUNIT1.TDt_BSF',
          chartId: 'type-1500-fingerprint-3',
        },
        {
          title: 'view.ext_cooling.chart_4',
          variableId: 'PRC.OHCOUNIT2.TDt',
          thresholdId: 'PRC.OHCOUNIT2.TDt_BSF',
          chartId: 'type-1500-fingerprint-4',
        },
        {
          title: 'view.ext_cooling.chart_5',
          variableId: 'PRC.TRWTR1I.TDt',
          thresholdId: 'PRC.TRWTR1I.TDt_BSF',
          chartId: 'type-1500-fingerprint-5',
        },
        {
          title: 'view.ext_cooling.chart_6',
          variableId: 'PRC.TRWTR1O.TDt',
          thresholdId: 'PRC.TRWTR1O.TDt_BSF',
          chartId: 'type-1500-fingerprint-6',
        },
        {
          title: 'view.ext_cooling.chart_7',
          variableId: 'AMB.TR001.TDt',
          thresholdId: 'AMB.TR001.TDt_BSF',
          chartId: 'type-1500-fingerprint-7',
        },
      ],
    },
  ];
  private readonly TAB_CONFIGS_TYPE_1200: TransformerTabConfig[] = [
    {
      // MV
      tabId: 'type1200Cooling',
      tabTitle: '',
      variables: [
        {
          title: 'CSS.TRWTR1IINT.TDt',
          variableId: 'CSS.TRWTR1IINT.TDt',
          thresholdId: 'CSS.TRWTR1IINT.TDt_BSF',
          chartId: 'type-1200-fingerprint-1',
        },
        {
          title: 'CSS.TRWTR1OINT.TDt',
          variableId: 'CSS.TRWTR1OINT.TDt',
          thresholdId: 'CSS.TRWTR1OINT.TDt_BSF',
          chartId: 'type-1200-fingerprint-2',
        },
        {
          title: 'CSS.TRWTR1RINT.TDt',
          variableId: 'CSS.TRWTR1RINT.TDt',
          thresholdId: 'CSS.TRWTR1RINT.TDt_BSF',
          chartId: 'type-1200-fingerprint-3',
        },
        {
          title: 'CSS.TRWTR1IEXT.TDt',
          variableId: 'CSS.TRWTR1IEXT.TDt',
          thresholdId: 'CSS.TRWTR1IEXT.TDt_BSF',
          chartId: 'type-1200-fingerprint-4',
        },
        {
          title: 'CSS.TRWTR1OEXT.TDt',
          variableId: 'CSS.TRWTR1OEXT.TDt',
          thresholdId: 'CSS.TRWTR1OEXT.TDt_BSF',
          chartId: 'type-1200-fingerprint-5',
        },
        {
          title: 'CSS.TRWTR1REXT.TDt',
          variableId: 'CSS.TRWTR1REXT.TDt',
          thresholdId: 'CSS.TRWTR1REXT.TDt_BSF',
          chartId: 'type-1200-fingerprint-6',
        },
      ],
    },
  ];
  private readonly TAB_CONFIGS_TYPE_1300_BRAKE: TransformerTabConfig[] = [
    {
      // BRAKE
      tabId: 'brake',
      tabTitle: '',
      variables: [
        {
          title: 'BRK.TROIL1N.TDt',
          variableId: 'BRK.TROIL1N.TDt',
          thresholdId: 'BRK.TROIL1N.TDt_BSF',
          chartId: 'type-1300-brake-1',
        },
        {
          title: 'BRK.PSREL1L.TDt',
          variableId: 'BRK.PSREL1L.TDt',
          thresholdId: 'BRK.PSREL1L.TDt_BSF',
          chartId: 'type-1300-brake-2',
        },
        {
          title: 'BRK.PSREL1R.TDt',
          variableId: 'BRK.PSREL1R.TDt',
          thresholdId: 'BRK.PSREL1R.TDt_BSF',
          chartId: 'type-1300-brake-3',
        },
      ],
    },
  ];
  private readonly TAB_CONFIGS_TYPE_1300_ACCUMULATOR: TransformerTabConfig[] = [
    {
      // ACCUMULATOR
      tabId: 'accumulator',
      tabTitle: '',
      variables: [
        {
          title: 'BRK.STACCCHARGE.TDt',
          variableId: 'BRK.STACCCHARGE.TDt',
          chartId: 'type-1300-accumulator-1',
        },
        {
          title: 'BRK.STCHARGED.TDt',
          variableId: 'BRK.STCHARGED.TDt',
          chartId: 'type-1300-accumulator-2',
        },
        {
          title: 'BRK.PSACC1N.TDt',
          variableId: 'BRK.PSACC1N.TDt',
          chartId: 'type-1300-accumulator-3',
        },
      ],
    },
  ];
  private readonly TAB_CONFIGS_TYPE_1300_BEARINGS: TransformerTabConfig[] = [
    {
      // BEARINGS
      tabId: 'feedBearing',
      tabTitle: 'control.text.feedBearing',
      variables: [
        {
          title: '002.TRPAD11.TDt',
          variableId: '002.TRPAD11.TDt',
          chartId: 'bearings-chart-no-1',
        },
        {
          title: '002.TRPAD21.TDt',
          variableId: '002.TRPAD21.TDt',
          chartId: 'bearings-chart-no-2',
        },
        {
          title: '002.TRPAD31.TDt',
          variableId: '002.TRPAD31.TDt',
          chartId: 'bearings-chart-no-3',
        },
        {
          title: '002.TRPAD41.TDt',
          variableId: '002.TRPAD41.TDt',
          chartId: 'bearings-chart-no-4',
        },
      ],
    },
    {
      tabId: 'thrustBearing',
      tabTitle: 'control.text.thrustBearing',
      variables: [
        {
          title: '001.TRPAD12I.TDt',
          variableId: '001.TRPAD12I.TDt',
          chartId: 'bearings-chart-no-1',
        },
        {
          title: '001.TRPAD12O.TDt',
          variableId: '001.TRPAD12O.TDt',
          chartId: 'bearings-chart-no-2',
        },
        {
          title: '001.TRPAD34I.TDt',
          variableId: '001.TRPAD34I.TDt',
          chartId: 'bearings-chart-no-3',
        },
        {
          title: '001.TRPAD34O.TDt',
          variableId: '001.TRPAD34O.TDt',
          chartId: 'bearings-chart-no-4',
        },
      ],
    },
    {
      tabId: 'dischargeBearing',
      tabTitle: 'control.text.dischargeBearing',
      variables: [
        {
          title: '003.TRPAD11.TDt',
          variableId: '003.TRPAD11.TDt',
          chartId: 'bearings-chart-no-1',
        },
        {
          title: '003.TRPAD21.TDt',
          variableId: '003.TRPAD21.TDt',
          chartId: 'bearings-chart-no-2',
        },
        {
          title: '003.TRPAD31.TDt',
          variableId: '003.TRPAD31.TDt',
          chartId: 'bearings-chart-no-3',
        },
        {
          title: '003.TRPAD41.TDt',
          variableId: '003.TRPAD41.TDt',
          chartId: 'bearings-chart-no-4',
        },
      ],
    },
  ];
  private readonly TAB_CONFIGS_TYPE_1700: TransformerTabConfig[] = [
    {
      // TR1
      tabId: 'tr1',
      tabTitle: 'control.text.transformer1',
      variables: [
        {
          title: 'view.ecsys.chart_1',
          variableId: 'TR1.CR001.TDt',
          chartId: 'fingerprint-chart-no-1',
        },
        {
          title: 'view.ecsys.chart_2',
          variableId: 'TR1.TRWDG1N.TDt',
          chartId: 'fingerprint-chart-no-2',
        },
        {
          title: 'view.ecsys.chart_3',
          variableId: 'TR1.TROIL1N.TDt',
          chartId: 'fingerprint-chart-no-3',
        },
      ],
    },
    {
      // TR2
      tabId: 'tr2',
      tabTitle: 'control.text.transformer2',
      variables: [
        {
          title: 'view.ecsys.chart_1',
          variableId: 'TR2.CR001.TDt',
          chartId: 'fingerprint-chart-no-1',
        },
        {
          title: 'view.ecsys.chart_2',
          variableId: 'TR2.TRWDG1N.TDt',
          chartId: 'fingerprint-chart-no-2',
        },
        {
          title: 'view.ecsys.chart_3',
          variableId: 'TR2.TROIL1N.TDt',
          chartId: 'fingerprint-chart-no-3',
        },
      ],
    },
    {
      // TR3
      tabId: 'tr3',
      tabTitle: 'control.text.transformer3',
      variables: [
        {
          title: 'view.ecsys.chart_1',
          variableId: 'TR3.CR001.TDt',
          chartId: 'fingerprint-chart-no-1',
        },
        {
          title: 'view.ecsys.chart_2',
          variableId: 'TR3.TRWDG1N.TDt',
          chartId: 'fingerprint-chart-no-2',
        },
        {
          title: 'view.ecsys.chart_3',
          variableId: 'TR3.TROIL1N.TDt',
          chartId: 'fingerprint-chart-no-3',
        },
      ],
    },
    {
      // TRE
      tabId: 'tre',
      tabTitle: 'control.text.excitationTransformer',
      variables: [
        {
          title: 'view.ecsys.chart_1',
          variableId: 'TRE.CR001.TDt',
          chartId: 'fingerprint-chart-no-1',
        },
        {
          title: 'view.ecsys.chart_2',
          variableId: 'TRE.TRWDG1N.TDt',
          chartId: 'fingerprint-chart-no-2',
        },
        {
          title: 'view.ecsys.chart_3',
          variableId: 'TRE.TROIL1N.TDt',
          chartId: 'fingerprint-chart-no-3',
        },
      ],
    },
  ];
  private readonly TAB_CONFIGS_TYPE_1600_AIRGAP: TransformerTabConfig[] = [
    {
      // RD
      tabId: 'rd',
      tabTitle: 'Discharge Side',
      variables: [
        {
          title: 'view.airGap.chart_1',
          variableId: 'STA.AG000RD.01_Min',
          chartId: 'airgap-chart-no-1',
        },
        {
          title: 'view.airGap.chart_2',
          variableId: 'STA.AG180RD.01_Min',
          chartId: 'airgap-chart-no-2',
        },
        {
          title: 'view.airGap.chart_3',
          variableId: 'STA.AG090RD.01_Min',
          chartId: 'airgap-chart-no-3',
        },
        {
          title: 'view.airGap.chart_4',
          variableId: 'STA.AG270RD.01_Min',
          chartId: 'airgap-chart-no-4',
        },
      ],
    },
    {
      // RF
      tabId: 'rf',
      tabTitle: 'Feed Side',
      variables: [
        {
          title: 'view.airGap.chart_1',
          variableId: 'STA.AG000RF.01_Min',
          chartId: 'airgap-chart-no-1',
        },
        {
          title: 'view.airGap.chart_2',
          variableId: 'STA.AG180RF.01_Min',
          chartId: 'airgap-chart-no-2',
        },
        {
          title: 'view.airGap.chart_3',
          variableId: 'STA.AG090RF.01_Min',
          chartId: 'airgap-chart-no-3',
        },
        {
          title: 'view.airGap.chart_4',
          variableId: 'STA.AG270RF.01_Min',
          chartId: 'airgap-chart-no-4',
        },
      ],
    },
    {
      // FD
      tabId: 'fd',
      tabTitle: 'Discharge - Feed',
      variables: [
        {
          title: 'view.airGap.chart_1',
          variableId: 'STA.AG000FD.01_Min',
          chartId: 'airgap-chart-no-1',
        },
        {
          title: 'view.airGap.chart_2',
          variableId: 'STA.AG180FD.01_Min',
          chartId: 'airgap-chart-no-2',
        },
        {
          title: 'view.airGap.chart_3',
          variableId: 'STA.AG090FD.01_Min',
          chartId: 'airgap-chart-no-3',
        },
        {
          title: 'view.airGap.chart_4',
          variableId: 'STA.AG270FD.01_Min',
          chartId: 'airgap-chart-no-4',
        },
      ],
    },
  ];
  private readonly TAB_CONFIGS_TYPE_1600_COOLING: TransformerTabConfig[] = [
    {
      // Segment 1
      tabId: 'seg1',
      tabTitle: 'Segment 1',
      variables: [
        {
          title: 'view.cooling.chart_1',
          variableId: 'SCS.DC001CA.TDt',
          chartId: 'cooling-chart-no-1',
        },
        {
          title: 'view.cooling.chart_2',
          variableId: 'SCS.TRAIR1I.TDt',
          chartId: 'cooling-chart-no-2',
        },
        {
          title: 'view.cooling.chart_3',
          variableId: 'SCS.TRAIR1D.TDt',
          chartId: 'cooling-chart-no-3',
        },
        {
          title: 'view.cooling.chart_4',
          variableId: 'SCS.DC001AW.TDt',
          chartId: 'cooling-chart-no-4',
        },
        {
          title: 'view.cooling.chart_5',
          variableId: 'SCS.TRWTR1I.TDt',
          chartId: 'cooling-chart-no-5',
        },
        {
          title: 'view.cooling.chart_6',
          variableId: 'SCS.TRWTR1D.TDt',
          chartId: 'cooling-chart-no-6',
        },
      ],
    },
    {
      // Segment 2
      tabId: 'seg2',
      tabTitle: 'Segment 2',
      variables: [
        {
          title: 'view.cooling.chart_1',
          variableId: 'SCS.DC002CA.TDt',
          chartId: 'cooling-chart-no-1',
        },
        {
          title: 'view.cooling.chart_2',
          variableId: 'SCS.TRAIR2I.TDt',
          chartId: 'cooling-chart-no-2',
        },
        {
          title: 'view.cooling.chart_3',
          variableId: 'SCS.TRAIR2D.TDt',
          chartId: 'cooling-chart-no-3',
        },
        {
          title: 'view.cooling.chart_4',
          variableId: 'SCS.DC002AW.TDt',
          chartId: 'cooling-chart-no-4',
        },
        {
          title: 'view.cooling.chart_5',
          variableId: 'SCS.TRWTR2I.TDt',
          chartId: 'cooling-chart-no-5',
        },
        {
          title: 'view.cooling.chart_6',
          variableId: 'SCS.TRWTR2D.TDt',
          chartId: 'cooling-chart-no-6',
        },
      ],
    },
    {
      // Segment 3
      tabId: 'seg3',
      tabTitle: 'Segment 3',
      variables: [
        {
          title: 'view.cooling.chart_1',
          variableId: 'SCS.DC003CA.TDt',
          chartId: 'cooling-chart-no-1',
        },
        {
          title: 'view.cooling.chart_2',
          variableId: 'SCS.TRAIR3I.TDt',
          chartId: 'cooling-chart-no-2',
        },
        {
          title: 'view.cooling.chart_3',
          variableId: 'SCS.TRAIR3D.TDt',
          chartId: 'cooling-chart-no-3',
        },
        {
          title: 'view.cooling.chart_4',
          variableId: 'SCS.DC003AW.TDt',
          chartId: 'cooling-chart-no-4',
        },
        {
          title: 'view.cooling.chart_5',
          variableId: 'SCS.TRWTR3I.TDt',
          chartId: 'cooling-chart-no-5',
        },
        {
          title: 'view.cooling.chart_6',
          variableId: 'SCS.TRWTR3D.TDt',
          chartId: 'cooling-chart-no-6',
        },
      ],
    },
    {
      // Segment 4
      tabId: 'seg4',
      tabTitle: 'Segment 4',
      variables: [
        {
          title: 'view.cooling.chart_1',
          variableId: 'SCS.DC004CA.TDt',
          chartId: 'cooling-chart-no-1',
        },
        {
          title: 'view.cooling.chart_2',
          variableId: 'SCS.TRAIR4I.TDt',
          chartId: 'cooling-chart-no-2',
        },
        {
          title: 'view.cooling.chart_3',
          variableId: 'SCS.TRAIR4D.TDt',
          chartId: 'cooling-chart-no-3',
        },
        {
          title: 'view.cooling.chart_4',
          variableId: 'SCS.DC004AW.TDt',
          chartId: 'cooling-chart-no-4',
        },
        {
          title: 'view.cooling.chart_5',
          variableId: 'SCS.TRWTR4I.TDt',
          chartId: 'cooling-chart-no-5',
        },
        {
          title: 'view.cooling.chart_6',
          variableId: 'SCS.TRWTR4D.TDt',
          chartId: 'cooling-chart-no-6',
        },
      ],
    },
  ];
  private readonly TAB_CONFIGS_TYPE_1600_STATORTREND: TransformerTabConfig[] = [
    {
      // xAxis
      tabId: 'xAxis',
      tabTitle: 'xAxis',
      variables: [
        {
          title: 'view.statorTrend.chart_1_x',
          variableId: 'STS.AC045AN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-1',
        },
        {
          title: 'view.statorTrend.chart_2_x',
          variableId: 'STS.AC135AN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-2',
        },
        {
          title: 'view.statorTrend.chart_3_x',
          variableId: 'STS.AC225AN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-3',
        },
        {
          title: 'view.statorTrend.chart_4_x',
          variableId: 'STS.AC315AN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-4',
        },
      ],
    },
    {
      // yAxis
      tabId: 'yAxis',
      tabTitle: 'yAxis',
      variables: [
        {
          title: 'view.statorTrend.chart_1_y',
          variableId: 'STS.AC045HN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-1',
        },
        {
          title: 'view.statorTrend.chart_2_y',
          variableId: 'STS.AC135HN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-2',
        },
        {
          title: 'view.statorTrend.chart_3_y',
          variableId: 'STS.AC225HN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-3',
        },
        {
          title: 'view.statorTrend.chart_4_y',
          variableId: 'STS.AC315HN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-4',
        },
      ],
    },
    {
      // zAxis
      tabId: 'zAxis',
      tabTitle: 'zAxis',
      variables: [
        {
          title: 'view.statorTrend.chart_1_z',
          variableId: 'STS.AC045VN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-1',
        },
        {
          title: 'view.statorTrend.chart_2_z',
          variableId: 'STS.AC135VN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-2',
        },
        {
          title: 'view.statorTrend.chart_3_z',
          variableId: 'STS.AC225VN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-3',
        },
        {
          title: 'view.statorTrend.chart_4_z',
          variableId: 'STS.AC315VN.01_RMSAcc',
          chartId: 'statorTrend-chart-no-4',
        },
      ],
    },
  ];
  private readonly TAB_CONFIGS_TYPE_1600_FOUNDATIONTREND: TransformerTabConfig[] = [
    {
      // xAxis
      tabId: 'xAxis',
      tabTitle: 'xAxis',
      variables: [
        {
          title: 'view.foundationVibration.chart_1',
          variableId: 'FOD.AC090AN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-1',
        },
        {
          title: 'view.foundationVibration.chart_2',
          variableId: 'FOD.AC270AN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-2',
        },
        {
          title: 'view.foundationVibration.chart_3',
          variableId: 'SOP.AC090AN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-3',
        },
        {
          title: 'view.foundationVibration.chart_4',
          variableId: 'SOP.AC270AN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-4',
        },
      ],
    },
    {
      // yAxis
      tabId: 'yAxis',
      tabTitle: 'yAxis',
      variables: [
        {
          title: 'view.foundationVibration.chart_1',
          variableId: 'FOD.AC090HN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-1',
        },
        {
          title: 'view.foundationVibration.chart_2',
          variableId: 'FOD.AC270HN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-2',
        },
        {
          title: 'view.foundationVibration.chart_3',
          variableId: 'SOP.AC090HN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-3',
        },
        {
          title: 'view.foundationVibration.chart_4',
          variableId: 'SOP.AC270HN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-4',
        },
      ],
    },
    {
      // zAxis
      tabId: 'zAxis',
      tabTitle: 'zAxis',
      variables: [
        {
          title: 'view.foundationVibration.chart_1',
          variableId: 'FOD.AC090VN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-1',
        },
        {
          title: 'view.foundationVibration.chart_2',
          variableId: 'FOD.AC270VN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-2',
        },
        {
          title: 'view.foundationVibration.chart_3',
          variableId: 'SOP.AC090VN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-3',
        },
        {
          title: 'view.foundationVibration.chart_4',
          variableId: 'SOP.AC270VN.01_RMSAcc',
          chartId: 'foundationVibration-chart-no-4',
        },
      ],
    },
  ];

  private facadeBaseUrl: string;

  constructor(private clientService: ClientService, private http: HttpClient) {
    this.facadeBaseUrl = this.clientService.getFacadeBaseUrl();
  }

  getConfig(refType: string): TransformerTabConfig[] {
    if (refType === '1400') {
      return this.TAB_CONFIGS_TYPE_1400;
    }
    if (refType === '1500') {
      return this.TAB_CONFIGS_TYPE_1500;
    }
    if (refType === '1700') {
      return this.TAB_CONFIGS_TYPE_1700;
    }
    if (refType === 'type1600airgap') {
      return this.TAB_CONFIGS_TYPE_1600_AIRGAP;
    }
    if (refType === 'type1600statorTrend') {
      return this.TAB_CONFIGS_TYPE_1600_STATORTREND;
    }
    if (refType === 'type1600foundationTrend') {
      return this.TAB_CONFIGS_TYPE_1600_FOUNDATIONTREND;
    }
    if (refType === 'type1600cooling') {
      return this.TAB_CONFIGS_TYPE_1600_COOLING;
    }
    if (refType === 'type1300Brake') {
      return this.TAB_CONFIGS_TYPE_1300_BRAKE;
    }
    if (refType === 'type1300Bearings') {
      return this.TAB_CONFIGS_TYPE_1300_BEARINGS;
    }
    if (refType === 'type1300Accumulator') {
      return this.TAB_CONFIGS_TYPE_1300_ACCUMULATOR;
    }
    if (refType === 'type1200Cooling') {
      return this.TAB_CONFIGS_TYPE_1200;
    }
    return undefined;
  }

  getValuesByOperatingPoint(
    assetId: string, propertyPath: string, operatingPointValue: string | number,
    from: string, to: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('operatingPointValue', operatingPointValue);
    params = from ? params.append('filter[from]', from) : params;
    params = to ? params.append('filter[to]', to): params;
    return this.http.get(
      `${this.facadeBaseUrl}diagnose/assets/${assetId}/properties/${propertyPath}/valuesbyoperatingpoint/resolution`, {
        params
      });
  }

  getValuesByOperatingPointRecent(
    assetId: string, propertyPaths: Array<string>, operatingPointValue: string | number,
    from: string, to: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('operatingPointValue', operatingPointValue);
    propertyPaths.forEach((p) => {
      params = params.append('properties', p);
    })
    params = from ? params.append('filter[from]', from) : params;
    params = to ? params.append('filter[to]', to) : params;
    return this.http.get(
      `${this.facadeBaseUrl}diagnose/assets/${assetId}/valuesbyoperatingpoint/recent`, {
        params
      });
  }

}
